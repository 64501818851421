import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import store from './store';
import { unregister } from './registerServiceWorker';
import * as Sentry from '@sentry/browser';
const pkg = require('../package.json');

// Prepare Sentry init object.
let sentryInit = {
  dsn: process.env.REACT_APP_SENTRYKEY,
  environment:
    process.env.REACT_APP_ENV !== 'local-dev'
      ? process.env.REACT_APP_ENV
      : 'local',
  beforeSend(event, hint) {
    if (hint.originalException === 'Timeout') return null;
    return event;
  },
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || 0.1,
  integrations: [new Sentry.browserTracingIntegration()],
};

// Prepare Sentry additional variables.
if (process.env.REACT_APP_GITHUB_SHA) {
  const env = process.env.REACT_APP_ENV || 'local-dev';
  // Set App release.
  let APP_RELEASE = `${env}-${process.env.REACT_APP_GITHUB_SHA}`;
  // Get prod tag name.
  if (process.env.REACT_APP_ENV === 'prod') {
    APP_RELEASE = process.env.REACT_APP_GITHUB_REF;
  }
  sentryInit.release = `${pkg.name}-${APP_RELEASE}`;
  if (process.env.REACT_APP_ENV === 'dev') {
    console.log(sentryInit);
  }
}

// Init Sentry with no available build variables.
Sentry.init(sentryInit);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App {...window.xprops} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
unregister();
