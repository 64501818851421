import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWesaluteRecaptcha } from 'hooks';
import * as Sentry from '@sentry/browser';
import Preloader from 'components/Preloader';
import SignUpForm from 'components/Sign/SignUpForm/SignUpForm';
import { analytics, api, auth, db, getCustomRoute, helpers } from 'actions';
import staticTexts from 'texts';

const INITIAL_STATE_FIELDS = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
};

const SignUpFormContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [fields, setFields] = useState(INITIAL_STATE_FIELDS);
  const [error, setError] = useState(null);
  const [isInvalid, setIsInvalid] = useState(true);
  const [finish, setFinish] = useState(false);
  const [recaptcha] = useWesaluteRecaptcha('signup');
  const [loading, setLoading] = useState(false);
  const [emailValidationInProgress, setEmailValidationInProgress] =
    useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [fieldsError, setFieldsError] = useState({});
  const authUser = useSelector((state) => state.sessionState.authUser);

  useEffect(() => {
    analytics.page('Sign Up Form');
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (authUser) {
      getCustomRoute(history);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (finish && recaptcha) {
      const { firstname, lastname, email, password } = fields;

      setLoading(true);

      // Sign up with username and password.
      let idToken;
      // Check email again before actual user creation.
      api
        .verifyEmailDomain(email)
        .then(() => {
          return auth.doCreateUserWithEmailAndPassword(email, password);
        })
        .then(async (user) => {
          idToken = await user.user.getIdToken();
          // Update auth profile with display name.
          return user.user.updateProfile({
            displayName: firstname + ' ' + lastname,
          });
        })
        .then(() => {
          // Create a user in Firebase Database too.
          const user = {
            first_name: firstname,
            last_name: lastname,
          };
          return api.memberCreate(user, idToken, dispatch);
        })
        .then(() => {
          setInitialState();
          getCustomRoute(history);
        })
        .catch((error) => {
          if (error.code === 'auth/email-already-in-use') {
            auth.processSignInMethodsForEmail(
              email,
              staticTexts,
              dispatch,
              db.setErrorPage,
              error
            );
          } else {
            Sentry.captureException(error);
          }
          console.error(error);

          const apiError =
            error?.response?.data?.error?.message ?? error?.message;

          setFieldsError({
            ...fieldsError,
            email: apiError,
          });

          // Set state error with the catch error.
          setError(error);
          setLoading(false);
          setFinish(false);
        });
    }
    // eslint-disable-next-line
  }, [recaptcha, finish])

  useEffect(() => {
    checkValidForm();
    // eslint-disable-next-line
  }, [fieldsError, fields, emailValidationInProgress])

  const setInitialState = () => {
    setFields(INITIAL_STATE_FIELDS);
    setError(null);
    setIsInvalid(true);
    setLoading(false);
    setPasswordType('password');
    setFieldsError({});
    setFinish(false);
  };

  const onSubmit = () => {
    setFinish(true);
    setLoading(true);
  };

  const signLink = (e, route) => {
    e.preventDefault();
    history.push(route);
  };

  const doValidateEmail = (email) => {
    setEmailValidationInProgress(true);

    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regMilEmail = RegExp(
      '^[A-Za-z0-9._%+-]+@([a-z0-9][-a-z0-9\\.]*[a-z0-9].mil)$'
    );

    // Check if it is a valid email format.
    if (!reg.test(String(email).toLowerCase())) {
      setFieldsError({
        ...fieldsError,
        email: staticTexts.EmailVerificationNotValid,
      });
      setEmailValidationInProgress(false);
    } else if (regMilEmail.test(String(email).toLowerCase())) {
      // Check if it is a .mil email.
      setFieldsError({
        ...fieldsError,
        email: staticTexts.EmailVerificationActiveDutyEmail,
      });
      setEmailValidationInProgress(false);
    } else {
      // Check if it is an active domain email.
      api
        .verifyEmailDomain(email)
        .then(() => {
          setFieldsError({
            ...fieldsError,
            email: false,
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          const apiError =
            error?.response?.data?.error?.message ?? error?.message;
          setFieldsError({
            ...fieldsError,
            email: apiError,
          });
        })
        .finally(() => {
          setEmailValidationInProgress(false);
        });

      return true;
    }
  };

  const doValidatePassword = (pass) => {
    // Validate password.
    const regLenght = /^.{8,}$/;
    const regNumber = /\d+/;
    const regLetter = /[A-Za-z]/;

    if (regLenght.test(pass) && regNumber.test(pass) && regLetter.test(pass)) {
      setFieldsError({
        ...fieldsError,
        password: false,
      });
    } else {
      let passwordError = staticTexts.PasswordVerificationTitle;
      if (!regLenght.test(pass)) {
        passwordError += staticTexts.PasswordVerificationEightCharacters;
      }
      if (!regNumber.test(pass)) {
        passwordError += staticTexts.PasswordVerificationOneNumber;
      }
      if (!regLetter.test(pass)) {
        passwordError += staticTexts.PasswordVerificationOneLetter;
      }

      setFieldsError({
        ...fieldsError,
        password: passwordError,
      });
    }
  };

  const doValidateFirstName = (firstname) => {
    // Validate firstname.
    if (helpers.isValidName(firstname)) {
      setFieldsError({
        ...fieldsError,
        firstname: false,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        firstname: staticTexts.FirstNameVerificationNotValid,
      });
    }
  };

  const doValidateLastName = (lastname) => {
    // Validate lastname.
    if (helpers.isValidName(lastname)) {
      setFieldsError({
        ...fieldsError,
        lastname: false,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        lastname: staticTexts.LastNameVerificationNotValid,
      });
    }
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Put email in lowercase and limit the first and last names to 60 characters.
    if (fieldName === 'email') {
      fieldValue = fieldValue.toLowerCase();
    } else if (fieldName === 'firstname' || fieldName === 'lastname') {
      fieldValue = fieldValue.substring(0, 60);
    }

    // Set field.
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticTexts.FieldIsRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'email') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateEmail(e.target.value);
      }
    } else if (customValidator === 'password') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidatePassword(e.target.value);
      }
    } else if (customValidator === 'firstname') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateFirstName(e.target.value);
      }
    } else if (customValidator === 'lastname') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateLastName(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  const checkValidForm = () => {
    const { firstname, lastname, email, password } = fields;

    if (
      !emailValidationInProgress &&
      ((typeof fieldsError.firstname === 'undefined' &&
        firstname !== '' &&
        helpers.isValidName(firstname)) ||
        fieldsError.firstname === false) &&
      ((typeof fieldsError.lastname === 'undefined' &&
        lastname !== '' &&
        helpers.isValidName(lastname)) ||
        fieldsError.lastname === false) &&
      ((typeof fieldsError.email === 'undefined' && email !== '') ||
        fieldsError.email === false) &&
      ((typeof fieldsError.password === 'undefined' && password !== '') ||
        fieldsError.password === false)
    ) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  const togglePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  if (loading) {
    return <Preloader title={staticTexts.SignUpFormLoader} waitFor={60000} />;
  } else {
    return (
      <SignUpForm
        onSubmit={onSubmit}
        firstname={fields.firstname}
        signLink={signLink}
        onChangeInput={onChangeInput}
        lastname={fields.lastname}
        password={fields.password}
        email={fields.email}
        isInvalid={isInvalid}
        error={error}
        fieldsError={fieldsError}
        passwordType={passwordType}
        togglePasswordType={togglePasswordType}
        inputValidation={inputValidation}
        emailValidationInProgress={emailValidationInProgress}
      />
    );
  }
};

export default SignUpFormContainer;
