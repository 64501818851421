import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWesaluteRecaptcha } from 'hooks';
import Preloader from 'components/Preloader';
import SignInForm from 'components/Sign/SignIn/SignInForm/SignInForm';
import EmailDialog from 'components/Sign/EmailDialog/EmailDialogContainer';

import { auth, analytics, db, getCustomRoute, api } from 'actions';
import staticTexts from 'texts';
import { FETCH_ACCOUNT } from 'constants/actiontypes';
import * as routes from '../../../../constants/routes';
import * as Sentry from '@sentry/browser';

const INITIAL_STATE_FIELDS = {
  email: '',
  password: '',
};

const SignInFormContainer = () => {
  const { setErrorPage } = db;
  const history = useHistory();
  const dispatch = useDispatch();
  const [fields, setFields] = useState(INITIAL_STATE_FIELDS);
  const [finish, setFinish] = useState(false);
  const [recaptcha] = useWesaluteRecaptcha('login');
  const [error, setError] = useState(null);
  const [isInvalid, setIsInvalid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [fieldsError, setFieldsError] = useState({});
  const accountData = useSelector((state) => state.accountState.account);

  const [signAuthUser, setSignAuthUser] = useState(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [authUserEmail, setAuthUserEmail] = useState(null);

  useEffect(() => {
    if (accountData) {
      getCustomRoute(history);
    }
  }, [accountData]);

  useEffect(() => {
    analytics.page('Sign In Form');
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    checkValidForm();
    // eslint-disable-next-line
  }, [fieldsError, fields])

  useEffect(() => {
    if (finish && recaptcha) {
      const { email, password } = fields;

      setError(null);
      setLoading(true);

      // Sign in with email and password.
      auth
        .doSignInWithEmailAndPassword(email, password)
        .then(async (credentials) => {
          setInitialState();
          setSignAuthUser(credentials);
          await credentials.user
            .getIdToken()
            .then((idToken) => api.memberLoad(idToken))
            .then(({ data }) => {
              dispatch({
                type: FETCH_ACCOUNT,
                payload: data?.data,
              });
            })
            .catch(() => {
              // Process user creation.
              setIsNewUser(true);
              setShowEmailForm(true);
              setAuthUserEmail(email);
            });
        })
        .catch((error) => {
          // Process Firebase Auth existing accounts messages.
          auth
            .processSignInMethodsForEmail(
              email,
              staticTexts,
              dispatch,
              setErrorPage,
              error
            )
            .then((status) => {
              if (!status) {
                setError(error);
                setFinish(false);
                setLoading(false);
              }
            });

          if (error.code === 'auth/wrong-password') {
            console.error('email-pass-sign-in', error);
          }
        });
    }
    // eslint-disable-next-line
  }, [recaptcha, finish])

  const setInitialState = () => {
    setFields(INITIAL_STATE_FIELDS);
    setError(null);
    setIsInvalid(true);
    setLoading(false);
    setPasswordType('password');
    setFieldsError({});
  };

  const signInWithEmailAndPassword = (event) => {
    event.preventDefault();

    setFinish(true);
    setLoading(true);
  };

  const doValidateEmail = (email) => {
    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Check if it is a valid email format.
    if (!reg.test(String(email).toLowerCase())) {
      setFieldsError({
        ...fieldsError,
        email: staticTexts.EmailVerificationNotValid,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        email: false,
      });
    }
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Set field.
    // Remove empty spaces if field is email.
    setFields({
      ...fields,
      [fieldName]: event.target.id === 'email' ? fieldValue.trim() : fieldValue,
    });
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticTexts.FieldIsRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'email') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidateEmail(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  const checkValidForm = () => {
    const { email, password } = fields;

    if (
      ((typeof fieldsError.email === 'undefined' && email !== '') ||
        fieldsError.email === false) &&
      ((typeof fieldsError.password === 'undefined' && password !== '') ||
        fieldsError.password === false)
    ) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  const togglePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const onSubmitEmail = (email, firstName, lastName) => {
    setLoading(true);
    // Create a user in Firebase Database if it is a new user with retrieve before auth user and provided email.
    processUser(signAuthUser, email, firstName, lastName, true);
  };

  const goToSignIn = () => {
    setShowEmailForm(false);
    history.push(routes.SIGN_IN);
  };

  const processUser = async (
    authUser,
    email,
    firstName,
    lastName,
    isNewUser = false
  ) => {
    const user = signAuthUser?.user ?? authUser?.user;
    const idToken = await user.getIdToken();
    const userData = {
      first_name: firstName,
      last_name: lastName,
    };
    if (email) {
      userData.email = email;
    }
    const method = isNewUser ? 'memberCreate' : 'memberUpdate';
    api[method](userData, idToken, dispatch).catch((error) => {
      Sentry.captureException(error);
      console.error(`social-${isNewUser ? 'create' : 'update'}-user`, error);
      const apiError = error?.response?.data?.error?.message ?? error?.message;

      // Set state error with the catch error.
      dispatch(
        setErrorPage(
          staticTexts.GeneralErrorTitle,
          apiError,
          'error',
          false,
          staticTexts.GeneralErrorClose,
          routes.SIGN_OUT,
          false,
          true
        )
      );
    });
  };

  if (loading) {
    return <Preloader title={staticTexts.SignInFormLoader} />;
  } else {
    return (
      <>
        <SignInForm
          onSubmit={signInWithEmailAndPassword}
          password={fields.password}
          passwordType={passwordType}
          togglePasswordType={togglePasswordType}
          onChangeInput={onChangeInput}
          email={fields.email}
          isInvalid={isInvalid}
          error={error}
          inputValidation={inputValidation}
          fieldsError={fieldsError}
        />
        <EmailDialog
          isNewUser={isNewUser}
          showEmailForm={showEmailForm}
          authUserEmail={authUserEmail}
          authUserFirstName={''}
          authUserLastName={''}
          onSubmitEmail={onSubmitEmail}
          goToSignIn={goToSignIn}
        />
      </>
    );
  }
};

export default SignInFormContainer;
