import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import staticTexts from 'texts/index.json';

const useStyles = makeStyles(() => ({
  quote: {
    textAlign: 'left',
    fontSize: '10px',
    lineHeight: '17px',
    fontWeight: 300,
    padding: '20px',
    background: '#F8F9FA',
    marginBottom: '24px',
    '& i': {
      fontWeight: 400,
    },
  },
  info: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 700,
    marginBottom: '8px',
  },
}));

const EmailDetails = () => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.quote} component="div">
      <div className={classes.info}>
        {staticTexts.VerifyEmailSentTitleAbove}
      </div>
      From: <i>{staticTexts.VerifyEmailSentFrom}</i>
      <br />
      Title: <i>{staticTexts.VerifyEmailSentEmailTitle}</i>
    </Typography>
  );
};

export default EmailDetails;
