export const SIGN_UP = '/';
export const SIGN_UP_EMAIL = '/signupform';
export const USER_DASHBOARD = '/user';
export const SIGN_IN = '/signin';
export const SIGN_IN_EMAIL = '/signinform';
export const SIGN_OUT = '/signout';
export const PASSWORD_FORGET = '/password-forget';
export const PASSWORD_FORGET_RESET = '/password-forget-reset';
export const PHONE_VERIFICATION = '/phone-verification';
export const EMAIL_VERIFICATION = '/email-verification';
export const EMAIL_VERIFICATION_EDIT = '/email-verification-edit';
export const VERIFY = '/verify';
export const VERIFY_APP_VERIFICATION = '/verification';
export const SELECT_PLAN = '/select-plan';
export const CONTACT_US = '/contact-us';
export const HYDRA_CONSENT = '/consent';
export const PRIVACY = '/privacy';
export const PRIVACY_REGISTER = '/privacy/register';
export const MANAGE_PROVIDERS = '/manage/providers';
export const MANAGE_PROVIDERS_FACEBOOK_DEEPLINK = '/manage/facebook';
export const MANAGE_PROVIDERS_GOOGLE_DEEPLINK = '/manage/google';
export const MANAGE_PROVIDERS_FACEBOOK_DISCONNECT_DEEPLINK =
  '/manage/facebook/disconnect';
export const MANAGE_PROVIDERS_GOOGLE_DISCONNECT_DEEPLINK =
  '/manage/google/disconnect';
export const SIGNUP_WITH_REDIRECT = '/signup/:provider';
export const EMAIL_CONFIRM = '/email-confirm';
