import React from 'react';
import { useHistory } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import ContainerBox from 'components/Common/ContainerBox';
import * as routes from 'constants/routes';
import staticTexts from 'texts';

const styleOverride = {};

const SignUpForm = (props) => {
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const customStyles = { ...defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();
  const history = useHistory();

  const {
    onSubmit,
    firstname,
    onChangeInput,
    lastname,
    password,
    passwordType,
    email,
    isInvalid,
    togglePasswordType,
    inputValidation,
    fieldsError,
    signLink,
    emailValidationInProgress,
  } = props;

  let passwordDescription = fieldsError.password
    ? fieldsError.password
    : staticTexts.PasswordVerificationDescription;

  let emailError = reactStringReplace(
    fieldsError.email,
    '@signin',
    (match, i) => (
      <Link
        key={i}
        href={'#'}
        className={classes.link}
        onClick={(e) => signLink(e, routes.SIGN_IN)}
        data-id="signin-link"
      >
        {staticTexts.EmailVerificationExistingAccountLink}
      </Link>
    )
  );

  return (
    <ContainerBox>
      <IconButton
        aria-label="back"
        className={classes.back}
        onClick={() => history.push(routes.SIGN_UP)}
      >
        <ArrowBackIosIcon fontSize="medium" />
      </IconButton>
      <form className={classes.form} noValidate autoComplete="off">
        <FormControl error={!!fieldsError.firstname} variant="filled" fullWidth>
          <InputLabel htmlFor="firstname">{staticTexts.FirstName}</InputLabel>
          <FilledInput
            id="firstname"
            className={classes.field}
            value={firstname}
            onChange={(e) => {
              onChangeInput(e);
              inputValidation(e, staticTexts.FirstName, true, 'firstname');
            }}
            onBlur={(e) => {
              inputValidation(e, staticTexts.FirstName, true, 'firstname');
            }}
            data-id="firstname"
          />
          <FormHelperText className={classes.marginHelper}>
            {fieldsError.firstname}
          </FormHelperText>
        </FormControl>
        <FormControl error={!!fieldsError.lastname} variant="filled" fullWidth>
          <InputLabel htmlFor="lastname">{staticTexts.LastName}</InputLabel>
          <FilledInput
            id="lastname"
            className={classes.field}
            value={lastname}
            onChange={(e) => {
              onChangeInput(e);
              inputValidation(e, staticTexts.LastName, true, 'lastname');
            }}
            onBlur={(e) => {
              inputValidation(e, staticTexts.LastName, true, 'lastname');
            }}
            data-id="lastname"
          />
          <FormHelperText className={classes.marginHelper}>
            {fieldsError.lastname}
          </FormHelperText>
        </FormControl>
        <FormControl error={!!fieldsError.email} variant="filled" fullWidth>
          <InputLabel htmlFor="email">{staticTexts.Email}</InputLabel>
          <FilledInput
            id="email"
            className={classes.field}
            disabled={emailValidationInProgress}
            value={email}
            onChange={(e) => onChangeInput(e)}
            onBlur={(e) => {
              inputValidation(e, staticTexts.Email, true, 'email');
            }}
            endAdornment={
              emailValidationInProgress ? (
                <InputAdornment position="end">
                  <CircularProgress />
                </InputAdornment>
              ) : null
            }
            data-id="email"
          />
          <FormHelperText className={classes.marginHelper}>
            {emailError}
          </FormHelperText>
        </FormControl>
        <FormControl error={!!fieldsError.password} variant="filled" fullWidth>
          <InputLabel htmlFor="password">
            {staticTexts.PasswordCreate}
          </InputLabel>
          <FilledInput
            id="password"
            className={classes.field}
            type={passwordType}
            value={password}
            onChange={(e) => {
              onChangeInput(e);
              inputValidation(e, staticTexts.Password, true, 'password');
            }}
            onBlur={(e) => {
              inputValidation(e, staticTexts.Password, true, 'password');
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordType}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {passwordType === 'password' ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
            data-id="password"
          />
          <FormHelperText className={classes.marginHelper}>
            {passwordDescription}
          </FormHelperText>
        </FormControl>
        <Button
          onClick={onSubmit}
          className={classes.button}
          variant={isInvalid ? 'outlined' : 'contained'}
          disabled={isInvalid}
          color="primary"
          disableElevation
          fullWidth
          data-id="signup-form-button"
          size="large"
        >
          {staticTexts.SignUpButton}
        </Button>
      </form>
    </ContainerBox>
  );
};

export default SignUpForm;
