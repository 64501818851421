import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import staticTexts from 'texts/index.json';
import Link from '@mui/material/Link';
import * as routes from 'constants/routes';

const styleOverride = {
  quote: {
    textAlign: 'left',
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 700,
    padding: '20px',
    background: '#F8F9FA',
  },
  note: {
    marginBottom: '11px',
  },
};

const EmailEditBlock = () => {
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const customStyles = {
    ...defaultDashboardStyles,
    ...defaultFormStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.quote} component="div">
      <div className={classes.note}>
        {staticTexts.EmailVerificationEditLinkDescription}
      </div>
      <Link
        component={RouterLink}
        to={routes.EMAIL_VERIFICATION_EDIT}
        className={classes.link}
        data-id="email-verification-edit-link"
      >
        {staticTexts.EmailVerificationEditLink}
      </Link>
    </Typography>
  );
};

export default EmailEditBlock;
