import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const DialogDefault = () => {
  const defaultDialogStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDialogStyles
  );
  const useStyles = makeStyles(() => defaultDialogStyles);
  const classes = useStyles();

  const dialogDataTitle = useSelector((state) => state.dialogState.title);
  const dialogDataDescription = useSelector(
    (state) => state.dialogState.description
  );

  return (
    <Dialog
      scroll="body"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      open={true}
      classes={{
        paper: classes.paper,
        paperScrollBody: classes.paperScrollBody,
      }}
    >
      {dialogDataTitle && (
        <DialogTitle id="dialog-title" className={classes.dialogTitle}>
          {dialogDataTitle}
        </DialogTitle>
      )}
      <DialogContent id="dialog-description" className={classes.dialogContent}>
        {dialogDataDescription}
      </DialogContent>
    </Dialog>
  );
};

export default DialogDefault;
