export const AUTH_COOKIE_NAME = 'loginTokenSession';
export const AUTH_COOKIE_NAME_OLD = 'customToken';
export const APPS_DOMAIN = 'wesalute.com';
export const APPS_DOMAIN_OLD = 'veteransadvantage.com';
export const TRACK_CLICK_CLASS = 'track-click';
export const VERIFICATION_EMAIL_SENT = 'WESALUTE_EMAIL_VEREIFICATION_SENT';
export const VERIFICATION_EMAIL_EDIT_VALUE =
  'WESALUTE_VERIFICATION_EMAIL_EDIT_VALUE';
export const VERIFICATION_EMAIL_SHOW_POPUP =
  'WESALUTE_VERIFICATION_EMAIL_SHOW_POPUP';
