const INITIAL_STATE = {
  title: '',
  description: '',
  active: false,
};

function dialogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_DIALOG': {
      return action.payload;
    }
    default:
      return state;
  }
}

export default dialogReducer;
