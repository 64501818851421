import * as Sentry from '@sentry/browser';
import { api } from './index';
import {
  FETCH_ACCOUNT,
  FETCH_ACCOUNT_UPDATE,
  FETCH_BRAND_INFO,
  FETCH_PRIVACY_INFO,
  SESSION_DB_UPDATE,
  SET_ERROR_PAGE,
  SET_NOTIFICATION,
  SET_DIALOG,
} from '../constants/actiontypes';

let loggedOut;

export const onSetAuthUser = (authUser) => (dispatch) => {
  loggedOut = false;
  dispatch({
    type: 'AUTH_USER_SET',
    authUser,
  });

  // If user logs out, reset accountData.
  if (!authUser) {
    loggedOut = true;
    dispatch({
      type: 'FETCH_ACCOUNT',
      payload: null,
    });
  }
};
// Update/Write session into the Store.
export const updateSession = (updates) => (dispatch) => {
  dispatch({
    type: SESSION_DB_UPDATE,
    payload: updates,
  });
};

// Get all the fields in the user document from DB.
export const fetchAccountInfo = (authUser) => (dispatch) => {
  if (authUser) {
    authUser
      .getIdToken()
      .then((idToken) => api.memberLoad(idToken))
      .then(({ data }) => {
        dispatch({
          type: FETCH_ACCOUNT,
          payload: loggedOut ? null : data?.data,
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  } else {
    const error = new Error('Empty auth user');
    Sentry.captureException(error);
    console.error(error);
  }
};

export const updateAccountData = (data) => (dispatch) =>
  dispatch({
    type: FETCH_ACCOUNT_UPDATE,
    payload: data,
  });

// Get brand filtered by brand name,
export const fetchBrandInfo = (providerToken) => (dispatch) => {
  // Prevent requests to API with empty token.
  const emptyValues = ['', '0', 'null', 'undefined', undefined];
  if (emptyValues.indexOf(providerToken) !== -1) {
    dispatch({
      type: FETCH_BRAND_INFO,
      payload: true,
    });
  } else {
    api
      .memberBrandsInfo(providerToken)
      .then(({ data }) => {
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: data?.data,
        });
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        dispatch({
          type: FETCH_BRAND_INFO,
          payload: true,
        });
      });
  }
};

/**
 * Get Privacy Policy and
 * Terms of Service data from db.
 *
 * @return {(function(*): void)|*}
 */
export const fetchPrivacyInfo = () => (dispatch) => {
  api
    .getPrivacyInfo()
    .then(({ data }) => {
      dispatch({
        type: FETCH_PRIVACY_INFO,
        payload: data?.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_PRIVACY_INFO,
        payload: 'empty',
      });
      Sentry.captureException(error);
      console.error('Error loading privacy data', error.message);
    });
};

// Dispatch the error page.
export const setErrorPage =
  (
    alertTitle,
    alertDesc,
    alertType,
    description,
    buttonTitle,
    buttonPage,
    buttonExternal,
    buttonClose,
    buttonSignOut,
    descriptionTitle
  ) =>
  (dispatch) => {
    dispatch({
      type: SET_ERROR_PAGE,
      payload: {
        alert: {
          title: alertTitle ? alertTitle : false,
          desc: alertDesc ? alertDesc : false,
          type: alertType ? alertType : false,
        },
        desc: description ? description : false,
        descTitle: descriptionTitle ? descriptionTitle : false,
        btn: {
          title: buttonTitle ? buttonTitle : false,
          page: buttonPage ? buttonPage : false,
          external: buttonExternal ? buttonExternal : false,
          close: buttonClose ? buttonClose : false,
          signout: buttonSignOut ? buttonSignOut : false,
        },
        active: true,
      },
    });
  };

// Clear the error page.
export const clearErrorPage = () => (dispatch) => {
  dispatch({
    type: SET_ERROR_PAGE,
    payload: {
      alert: {
        title: false,
        desc: false,
        type: false,
      },
      desc: false,
      descTitle: false,
      btn: {
        title: false,
        page: false,
        external: false,
        close: false,
        signout: false,
      },
      active: false,
    },
  });
};

// Set notification.
export const setNotification = (title, active, type) => (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION,
    payload: {
      title: title ?? '',
      active: active ?? false,
      type: type ?? 'success',
    },
  });
};

// Set dialog.
export const setDialog = (title, description, active) => (dispatch) => {
  dispatch({
    type: SET_DIALOG,
    payload: {
      title: title ?? '',
      description: description ?? '',
      active: active ?? false,
    },
  });
};

// Get the premium_brands_auth from blocks_premium_brands collection.
export const fetchWesaluteBrands = () => {
  return api
    .memberBrands()
    .then(({ data }) => data?.data)
    .catch(() => null);
};
